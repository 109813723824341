import React, { forwardRef } from 'react'
import * as constants from '../../util/constants'

const {
    ACTIVITIY_TYPE_WORD: WORD,
    ACTIVITIY_TYPE_EVENT: EVENT,
    ACTIVITIY_TYPE_PHONE: PHONE,
    ACTIVITIY_TYPE_OTHER: OTHER,
    ACTIVITIY_TYPE_EXCEL: EXCEL,
    ACTIVITIY_TYPE_BROWSER: BROWSER,
    ACTIVITIY_TYPE_OUTLOOK: OUTLOOK,
    ACTIVITIY_TYPE_POWERPOINT: POWERPOINT,
    ACTIVITIY_TYPE_CORRESPONDENCE: CORRESPONDENCE,
    ACTIVITIY_TYPE_TIMER: TIMER,
    ACTIVITIY_TYPE_PDF: PDF,
} = constants

const Word = () => (
    <>
        <path
            d="M14.6667 9H1.33333L0.333328 7.66667L1.66666 6.33333H14.3333L15.6667 7.66667L14.6667 9Z"
            fill="#12376B"
        />
        <path
            d="M13.6667 15.6667H2.33332C1.96499 15.6667 1.66666 15.3683 1.66666 15V1C1.66666 0.631667 1.96499 0.333333 2.33332 0.333333H10.3333L14.3333 4.33333V15C14.3333 15.3683 14.035 15.6667 13.6667 15.6667Z"
            fill="#E6E6E6"
        />
        <path
            d="M10.3333 0.333333V3.66667C10.3333 4.035 10.6317 4.33333 11 4.33333H14.3333L10.3333 0.333333Z"
            fill="#B3B3B3"
        />
        <path
            d="M15 13.6667H0.999995C0.631662 13.6667 0.333328 13.3683 0.333328 13V7.66667H15.6667V13C15.6667 13.3683 15.3683 13.6667 15 13.6667Z"
            fill="#27549B"
        />
        <path
            d="M5.183 10.6357C5.183 11.0727 5.06267 11.409 4.822 11.6453C4.58133 11.882 4.24333 12 3.808 12H2.961V9.38233H3.867C4.28733 9.38233 4.61133 9.48967 4.84 9.70467C5.06867 9.91967 5.183 10.2297 5.183 10.6357ZM4.44867 10.6607C4.44867 10.4207 4.40133 10.243 4.30633 10.127C4.21167 10.011 4.06733 9.95333 3.874 9.95333H3.668V11.4193H3.82567C4.04067 11.4193 4.198 11.3567 4.29833 11.232C4.39867 11.1073 4.44867 10.9173 4.44867 10.6607Z"
            fill="white"
        />
        <path
            d="M8.10099 10.6873C8.10099 11.128 7.99299 11.4627 7.77699 11.692C7.56099 11.9213 7.24466 12.0357 6.82799 12.0357C6.41733 12.0357 6.10266 11.9203 5.88333 11.69C5.66466 11.46 5.55533 11.1243 5.55533 10.684C5.55533 10.248 5.66399 9.91533 5.88199 9.68567C6.09933 9.456 6.41633 9.341 6.83166 9.341C7.248 9.341 7.56366 9.45533 7.77866 9.683C7.99366 9.91067 8.10099 10.246 8.10099 10.6873ZM6.29999 10.6873C6.29999 11.1937 6.47566 11.4463 6.82799 11.4463C7.00699 11.4463 7.14 11.385 7.22633 11.262C7.31266 11.139 7.356 10.9477 7.356 10.687C7.356 10.426 7.31233 10.2327 7.22433 10.108C7.13633 9.98333 7.00566 9.92067 6.83133 9.92067C6.47733 9.92133 6.29999 10.1767 6.29999 10.6873Z"
            fill="white"
        />
        <path
            d="M9.73933 9.925C9.571 9.925 9.43966 9.99367 9.34533 10.1317C9.25133 10.2693 9.20366 10.4593 9.20366 10.7017C9.20366 11.2057 9.39533 11.4577 9.77866 11.4577C9.89466 11.4577 10.0067 11.4413 10.1153 11.409C10.224 11.3767 10.333 11.338 10.4427 11.2927V11.8903C10.2247 11.9873 9.97733 12.0357 9.70166 12.0357C9.30633 12.0357 9.00366 11.921 8.79266 11.692C8.582 11.4627 8.477 11.1313 8.477 10.6987C8.477 10.4277 8.52766 10.1897 8.63 9.984C8.73233 9.77833 8.87866 9.62067 9.07 9.51033C9.262 9.4 9.48733 9.345 9.746 9.345C10.029 9.345 10.299 9.40633 10.557 9.52933L10.3407 10.086C10.244 10.0407 10.1473 10.0027 10.0507 9.97133C9.954 9.94033 9.85033 9.925 9.73933 9.925Z"
            fill="white"
        />
        <path
            d="M13.3113 12H12.493L11.9827 11.1817L11.478 12H10.6777L11.5457 10.6607L10.7293 9.38233H11.514L11.9867 10.1917L12.441 9.38233H13.2487L12.4197 10.716L13.3113 12Z"
            fill="white"
        />
    </>
)

const Excel = () => (
    <>
        <path
            d="M14.6667 9H1.33334L0.333344 7.66667L1.66668 6.33334H14.3333L15.6667 7.66667L14.6667 9Z"
            fill="#0E512B"
        />
        <path
            d="M13.6667 15.6667H2.33335C1.96502 15.6667 1.66669 15.3683 1.66669 15V1C1.66669 0.631669 1.96502 0.333336 2.33335 0.333336H10.3334L14.3334 4.33334V15C14.3334 15.3683 14.035 15.6667 13.6667 15.6667Z"
            fill="#E6E6E6"
        />
        <path
            d="M10.3333 0.333336V3.66667C10.3333 4.035 10.6317 4.33334 11 4.33334H14.3333L10.3333 0.333336Z"
            fill="#B3B3B3"
        />
        <path
            d="M15 13.6667H1.00001C0.631677 13.6667 0.333344 13.3683 0.333344 13V7.66667H15.6667V13C15.6667 13.3683 15.3683 13.6667 15 13.6667Z"
            fill="#1B7343"
        />
        <path
            d="M5.86401 12H5.04568L4.53535 11.1817L4.03068 12H3.23035L4.09868 10.6607L3.28235 9.38234H4.06668L4.53935 10.1917L4.99401 9.38234H5.80135L4.97235 10.716L5.86401 12Z"
            fill="white"
        />
        <path
            d="M6.15768 12V9.38234H6.86502V11.429H7.87302V12H6.15768Z"
            fill="white"
        />
        <path
            d="M10.0033 11.205C10.0033 11.3673 9.96201 11.5113 9.87968 11.6373C9.79735 11.7633 9.67835 11.8613 9.52335 11.931C9.36835 12.001 9.18601 12.0357 8.97735 12.0357C8.80301 12.0357 8.65701 12.0233 8.53868 11.999C8.42035 11.9747 8.29768 11.932 8.17001 11.871V11.2407C8.30501 11.31 8.44501 11.364 8.59068 11.4027C8.73635 11.4413 8.87001 11.4607 8.99168 11.4607C9.09668 11.4607 9.17368 11.4427 9.22268 11.4063C9.27168 11.37 9.29601 11.323 9.29601 11.2657C9.29601 11.23 9.28601 11.1987 9.26668 11.1717C9.24668 11.145 9.21535 11.1177 9.17168 11.0903C9.12835 11.063 9.01201 11.0067 8.82368 10.922C8.65301 10.8447 8.52501 10.7693 8.43968 10.6963C8.35435 10.6233 8.29101 10.54 8.25001 10.4457C8.20868 10.3513 8.18801 10.24 8.18801 10.111C8.18801 9.86967 8.27568 9.68167 8.45135 9.547C8.62701 9.41233 8.86801 9.345 9.17468 9.345C9.44568 9.345 9.72201 9.40767 10.0037 9.53267L9.78701 10.0787C9.54235 9.96667 9.33101 9.91067 9.15335 9.91067C9.06135 9.91067 8.99468 9.92667 8.95268 9.959C8.91068 9.99133 8.89001 10.0313 8.89001 10.0787C8.89001 10.13 8.91668 10.176 8.96968 10.2167C9.02268 10.2573 9.16668 10.3313 9.40201 10.4387C9.62768 10.5403 9.78401 10.649 9.87201 10.7653C9.95934 10.882 10.0033 11.0283 10.0033 11.205Z"
            fill="white"
        />
        <path
            d="M12.766 12H11.9477L11.4373 11.1817L10.9327 12H10.1323L11.0007 10.6607L10.1843 9.38234H10.9687L11.4413 10.1917L11.896 9.38234H12.7033L11.8743 10.716L12.766 12Z"
            fill="white"
        />
    </>
)

const PowerPoint = () => (
    <>
        <path
            d="M14.6667 9H1.33334L0.333336 7.66667L1.66667 6.33334H14.3333L15.6667 7.66667L14.6667 9Z"
            fill="#AA3111"
        />
        <path
            d="M13.6667 15.6667H2.33334C1.96501 15.6667 1.66667 15.3683 1.66667 15V1C1.66667 0.631669 1.96501 0.333336 2.33334 0.333336H10.3333L14.3333 4.33334V15C14.3333 15.3683 14.035 15.6667 13.6667 15.6667Z"
            fill="#E6E6E6"
        />
        <path
            d="M10.3333 0.333336V3.66667C10.3333 4.035 10.6317 4.33334 11 4.33334H14.3333L10.3333 0.333336Z"
            fill="#B3B3B3"
        />
        <path
            d="M15 13.6667H1C0.631669 13.6667 0.333336 13.3683 0.333336 13V7.66667H15.6667V13C15.6667 13.3683 15.3683 13.6667 15 13.6667Z"
            fill="#D44519"
        />
        <path
            d="M5.457 10.215C5.457 10.5077 5.37067 10.7337 5.19834 10.8927C5.02567 11.0523 4.781 11.1317 4.46334 11.1317H4.26467V12H3.55733V9.38234H4.46334C4.794 9.38234 5.04233 9.45467 5.208 9.59867C5.374 9.74334 5.457 9.94901 5.457 10.215ZM4.26434 10.5553H4.39334C4.49967 10.5553 4.584 10.5253 4.64667 10.4653C4.70933 10.4057 4.74067 10.3233 4.74067 10.2187C4.74067 10.042 4.643 9.95367 4.447 9.95367H4.26434V10.5553Z"
            fill="white"
        />
        <path
            d="M7.77367 10.215C7.77367 10.5077 7.68733 10.7337 7.515 10.8927C7.34233 11.0523 7.09767 11.1317 6.78 11.1317H6.58133V12H5.874V9.38234H6.78C7.11067 9.38234 7.359 9.45467 7.52467 9.59867C7.69067 9.74334 7.77367 9.94901 7.77367 10.215ZM6.58133 10.5553H6.71033C6.81667 10.5553 6.901 10.5253 6.96367 10.4653C7.02633 10.4057 7.05767 10.3233 7.05767 10.2187C7.05767 10.042 6.96 9.95367 6.764 9.95367H6.58133V10.5553Z"
            fill="white"
        />
        <path
            d="M9.34567 12H8.63834V9.96101H7.99934V9.38268H9.983V9.96101H9.34567V12Z"
            fill="white"
        />
        <path
            d="M12.715 12H11.8967L11.3863 11.1817L10.8817 12H10.0813L10.9497 10.6607L10.1333 9.38234H10.9177L11.3903 10.1917L11.845 9.38234H12.6523L11.8233 10.716L12.715 12Z"
            fill="white"
        />
    </>
)

const Browser = () => (
    <>
        <path
            d="M0.333328 8C0.333328 12.2273 3.77266 15.6667 8 15.6667C12.2273 15.6667 15.6667 12.2273 15.6667 8C15.6667 3.77267 12.2273 0.333336 8 0.333336C3.77266 0.333336 0.333328 3.77267 0.333328 8Z"
            fill="white"
        />
        <path
            d="M4.69233 7.604C4.889 5.95234 6.29633 4.66667 8 4.66667H14.903C13.661 2.10434 11.0337 0.333336 8 0.333336C5.629 0.333336 3.506 1.41567 2.09866 3.11167L4.69233 7.604Z"
            fill="#E86C60"
        />
        <path
            d="M7.99999 10.6667C9.47275 10.6667 10.6667 9.47276 10.6667 8C10.6667 6.52724 9.47275 5.33334 7.99999 5.33334C6.52724 5.33334 5.33333 6.52724 5.33333 8C5.33333 9.47276 6.52724 10.6667 7.99999 10.6667Z"
            fill="#43A6DD"
        />
        <path
            d="M9.31 11.0643C8.90733 11.237 8.465 11.3333 8 11.3333C6.76866 11.3333 5.69366 10.6603 5.11666 9.66467L5.11333 9.66667L1.66299 3.69034C0.824662 4.91934 0.333328 6.40334 0.333328 8C0.333328 11.79 3.098 14.9443 6.716 15.557L9.31 11.0643Z"
            fill="#72C472"
        />
        <path
            d="M9.99132 5.33334C10.804 5.94167 11.3333 6.909 11.3333 8C11.3333 8.60667 11.1677 9.17434 10.8833 9.66467L10.8867 9.66667L7.43599 15.6437C7.62232 15.6573 7.80999 15.6667 7.99999 15.6667C12.2277 15.6667 15.6667 12.2277 15.6667 8C15.6667 7.06267 15.497 6.16434 15.1877 5.33334H9.99132Z"
            fill="#EFD358"
        />
    </>
)

const Event = () => (
    <>
        <path
            d="M0.333332 15C0.333332 15.3682 0.631815 15.6667 0.999999 15.6667H15C15.3682 15.6667 15.6667 15.3682 15.6667 15V5H0.333332V15Z"
            fill="#E6E6E6"
        />
        <path
            d="M15 1.66667H0.999999C0.631815 1.66667 0.333332 1.96513 0.333332 2.33333V5H15.6667V2.33333C15.6667 1.96513 15.3682 1.66667 15 1.66667Z"
            fill="#E86C60"
        />
        <path
            d="M3.33333 3.33333V0.666667C3.33333 0.482574 3.48257 0.333334 3.66667 0.333334H4.33333C4.51743 0.333334 4.66667 0.482574 4.66667 0.666667V3.33333C4.66667 3.51743 4.51743 3.66667 4.33333 3.66667H3.66667C3.48257 3.66667 3.33333 3.51743 3.33333 3.33333Z"
            fill="#444444"
        />
        <path
            d="M11.3333 3.33333V0.666667C11.3333 0.482574 11.4826 0.333334 11.6667 0.333334H12.3333C12.5174 0.333334 12.6667 0.482574 12.6667 0.666667V3.33333C12.6667 3.51743 12.5174 3.66667 12.3333 3.66667H11.6667C11.4826 3.66667 11.3333 3.51743 11.3333 3.33333Z"
            fill="#444444"
        />
        <path
            d="M13.3333 11.3333H12.3333V9.33333H13.3333C13.5176 9.33333 13.6667 9.18424 13.6667 9C13.6667 8.81576 13.5176 8.66667 13.3333 8.66667H12.3333V7C12.3333 6.81576 12.1842 6.66667 12 6.66667C11.8158 6.66667 11.6667 6.81576 11.6667 7V8.66667H9.66667V7C9.66667 6.81576 9.51758 6.66667 9.33333 6.66667C9.14909 6.66667 9 6.81576 9 7V8.66667H7V7C7 6.81576 6.85091 6.66667 6.66667 6.66667C6.48242 6.66667 6.33333 6.81576 6.33333 7V8.66667H4.33333V7C4.33333 6.81576 4.18424 6.66667 4 6.66667C3.81576 6.66667 3.66667 6.81576 3.66667 7V8.66667H2.66667C2.48242 8.66667 2.33333 8.81576 2.33333 9C2.33333 9.18424 2.48242 9.33333 2.66667 9.33333H3.66667V11.3333H2.66667C2.48242 11.3333 2.33333 11.4824 2.33333 11.6667C2.33333 11.8509 2.48242 12 2.66667 12H3.66667V13.6667C3.66667 13.8509 3.81576 14 4 14C4.18424 14 4.33333 13.8509 4.33333 13.6667V12H6.33333V13.6667C6.33333 13.8509 6.48242 14 6.66667 14C6.85091 14 7 13.8509 7 13.6667V12H9V13.6667C9 13.8509 9.14909 14 9.33333 14C9.51758 14 9.66667 13.8509 9.66667 13.6667V12H11.6667V13.6667C11.6667 13.8509 11.8158 14 12 14C12.1842 14 12.3333 13.8509 12.3333 13.6667V12H13.3333C13.5176 12 13.6667 11.8509 13.6667 11.6667C13.6667 11.4824 13.5176 11.3333 13.3333 11.3333ZM4.33333 11.3333V9.33333H6.33333V11.3333H4.33333ZM7 11.3333V9.33333H9V11.3333H7ZM9.66667 11.3333V9.33333H11.6667V11.3333H9.66667Z"
            fill="#B3B3B3"
        />
    </>
)

const Phone = () => (
    <>
        <path
            d="M15.3333 6.99999C15.2449 6.99999 15.1601 6.96488 15.0976 6.90236C15.0351 6.83985 15 6.75507 15 6.66666C14.9984 5.16426 14.4009 3.72384 13.3385 2.66148C12.2762 1.59912 10.8357 1.00158 9.33333 0.999995C9.24493 0.999995 9.16014 0.964876 9.09763 0.902364C9.03512 0.839852 9 0.755067 9 0.666662C9 0.578256 9.03512 0.493471 9.09763 0.430959C9.16014 0.368447 9.24493 0.333328 9.33333 0.333328C11.0125 0.335181 12.6223 1.00304 13.8096 2.19036C14.997 3.37769 15.6648 4.98753 15.6667 6.66666C15.6667 6.75507 15.6315 6.83985 15.569 6.90236C15.5065 6.96488 15.4217 6.99999 15.3333 6.99999Z"
            fill="#49C549"
        />
        <path
            d="M12.6667 7C12.5783 7 12.4935 6.96488 12.431 6.90237C12.3685 6.83986 12.3333 6.75507 12.3333 6.66667C12.3324 5.87132 12.016 5.10882 11.4536 4.54642C10.8912 3.98402 10.1287 3.66764 9.33333 3.66667C9.24493 3.66667 9.16014 3.63155 9.09763 3.56904C9.03512 3.50652 9 3.42174 9 3.33333C9 3.24493 9.03512 3.16014 9.09763 3.09763C9.16014 3.03512 9.24493 3 9.33333 3C10.3054 3.00115 11.2374 3.38782 11.9248 4.07521C12.6122 4.76259 12.9989 5.69456 13 6.66667C13 6.75507 12.9649 6.83986 12.9024 6.90237C12.8399 6.96488 12.7551 7 12.6667 7Z"
            fill="#9EE09E"
        />
        <path
            d="M10.4587 9.725L9.26333 11.2193C7.41202 10.1313 5.86918 8.58857 4.781 6.73733L6.27533 5.542C6.45038 5.40177 6.57408 5.20754 6.62715 4.98962C6.68021 4.7717 6.65965 4.54234 6.56867 4.33733L5.20633 1.27066C5.10874 1.05064 4.93613 0.872463 4.71931 0.767933C4.5025 0.663403 4.25559 0.639325 4.02267 0.699997L1.43 1.37166C1.18809 1.43497 0.977788 1.58477 0.838898 1.79271C0.700008 2.00065 0.64217 2.25229 0.676334 2.5C1.13159 5.74 2.62916 8.74377 4.94269 11.0573C7.25623 13.3708 10.26 14.8684 13.5 15.3237C13.7476 15.358 13.9992 15.3002 14.207 15.1614C14.4149 15.0225 14.5646 14.8122 14.6277 14.5703L15.3 11.9773C15.3605 11.7444 15.3364 11.4976 15.2319 11.2808C15.1274 11.064 14.9493 10.8914 14.7293 10.7937L11.6633 9.43333C11.4585 9.34211 11.2293 9.32125 11.0113 9.37401C10.7934 9.42678 10.5991 9.55019 10.4587 9.725V9.725Z"
            fill="#3D6C7B"
        />
    </>
)

const Correspondence = () => (
    <>
        <path
            d="M8 12.3333C7.93766 12.3333 7.87566 12.316 7.821 12.2813L0.487664 7.61467C0.386331 7.55034 0.327664 7.436 0.333664 7.316C0.339997 7.19634 0.409997 7.08867 0.517664 7.035L8 2.33334L15.4823 7.035C15.5897 7.08867 15.66 7.196 15.6663 7.316C15.6727 7.436 15.6137 7.55 15.5123 7.61467L8.179 12.2813C8.12433 12.316 8.06233 12.3333 8 12.3333Z"
            fill="#A67C52"
        />
        <path
            d="M13 12.6667H3V0.666669C3 0.482669 3.14933 0.333336 3.33333 0.333336H12.6667C12.8507 0.333336 13 0.482669 13 0.666669V12.6667Z"
            fill="#E6E6E6"
        />
        <path
            d="M15.3333 15.6667C15.3053 15.6667 15.277 15.663 15.2493 15.656L7.58266 13.656L7.66666 11.3333L15.6667 7.33334V15.3333C15.6667 15.4367 15.6187 15.534 15.537 15.5973C15.4783 15.6427 15.4063 15.6667 15.3333 15.6667Z"
            fill="#B28F77"
        />
        <path
            d="M15.3333 15.6667H0.666662C0.482328 15.6667 0.333328 15.5173 0.333328 15.3333V7.33334L15.493 15.0407C15.6267 15.1137 15.6943 15.268 15.6563 15.4157C15.6187 15.5633 15.4857 15.6667 15.3333 15.6667Z"
            fill="#C19F85"
        />
        <path
            d="M10.6667 3.33334H5.33333C5.14933 3.33334 5 3.18434 5 3.00001C5 2.81567 5.14933 2.66667 5.33333 2.66667H10.6667C10.8507 2.66667 11 2.81567 11 3.00001C11 3.18434 10.8507 3.33334 10.6667 3.33334Z"
            fill="#B3B3B3"
        />
        <path
            d="M10.6667 5.66667H5.33333C5.14933 5.66667 5 5.51767 5 5.33333C5 5.149 5.14933 5 5.33333 5H10.6667C10.8507 5 11 5.149 11 5.33333C11 5.51767 10.8507 5.66667 10.6667 5.66667Z"
            fill="#B3B3B3"
        />
        <path
            d="M8.33333 8H5.33333C5.14933 8 5 7.851 5 7.66667C5 7.48234 5.14933 7.33334 5.33333 7.33334H8.33333C8.51733 7.33334 8.66667 7.48234 8.66667 7.66667C8.66667 7.851 8.51733 8 8.33333 8Z"
            fill="#B3B3B3"
        />
    </>
)

const Question = () => (
    <>
        <path
            d="M12.6667 15.6667H1.33334C0.965005 15.6667 0.666672 15.3683 0.666672 15V1C0.666672 0.631667 0.965005 0.333334 1.33334 0.333334H9.33334L13.3333 4.33333V15C13.3333 15.3683 13.035 15.6667 12.6667 15.6667Z"
            fill="#E56A59"
        />
        <path
            d="M9.33334 0.333334V3.66667C9.33334 4.035 9.63168 4.33333 10 4.33333H13.3333L9.33334 0.333334Z"
            fill="#B85548"
        />
        <path
            d="M7 10.3333C6.816 10.3333 6.66667 10.184 6.66667 10V8C6.66667 7.816 6.816 7.66667 7 7.66667C7.888 7.66667 8.66667 6.888 8.66667 6C8.66667 5.112 7.888 4.33333 7 4.33333C6.38233 4.33333 5.71367 4.79833 5.44433 5.41433C5.37033 5.583 5.174 5.65967 5.00533 5.58633C4.83667 5.51267 4.75967 5.316 4.83333 5.14733C5.20833 4.28933 6.11933 3.66667 7 3.66667C8.26467 3.66667 9.33333 4.73533 9.33333 6C9.33333 7.152 8.447 8.141 7.33333 8.30833V10C7.33333 10.184 7.184 10.3333 7 10.3333Z"
            fill="white"
        />
        <path
            d="M7.00001 12.6667C7.3682 12.6667 7.66668 12.3682 7.66668 12C7.66668 11.6318 7.3682 11.3333 7.00001 11.3333C6.63182 11.3333 6.33334 11.6318 6.33334 12C6.33334 12.3682 6.63182 12.6667 7.00001 12.6667Z"
            fill="white"
        />
    </>
)

const Email = () => (
    <>
        <path
            d="M15 13.3333H0.999999C0.631809 13.3333 0.333332 13.0349 0.333332 12.6667V1.33333C0.333332 0.965141 0.631809 0.666664 0.999999 0.666664H15C15.3682 0.666664 15.6667 0.965141 15.6667 1.33333V12.6667C15.6667 13.0349 15.3682 13.3333 15 13.3333Z"
            fill="#E6E6E6"
        />
        <path
            d="M8 8.66667C7.91471 8.66667 7.82943 8.63411 7.76432 8.56901L3.09766 3.90234C2.96745 3.77214 2.96745 3.5612 3.09766 3.43099C3.22786 3.30078 3.4388 3.30078 3.56901 3.43099L8 7.86198L12.431 3.43099C12.5612 3.30078 12.7721 3.30078 12.9023 3.43099C13.0325 3.5612 13.0325 3.77214 12.9023 3.90234L8.23568 8.56901C8.17057 8.63411 8.08529 8.66667 8 8.66667Z"
            fill="#B3B3B3"
        />
        <path
            d="M3.33333 10.6667C3.24804 10.6667 3.16276 10.6341 3.09765 10.569C2.96745 10.4388 2.96745 10.2279 3.09765 10.0977L4.43099 8.76432C4.56119 8.63411 4.77213 8.63411 4.90234 8.76432C5.03255 8.89453 5.03255 9.10547 4.90234 9.23567L3.56901 10.569C3.5039 10.6341 3.41862 10.6667 3.33333 10.6667Z"
            fill="#B3B3B3"
        />
        <path
            d="M12.6667 10.6667C12.5814 10.6667 12.4961 10.6341 12.431 10.569L11.0977 9.23567C10.9674 9.10547 10.9674 8.89453 11.0977 8.76432C11.2279 8.63411 11.4388 8.63411 11.569 8.76432L12.9023 10.0977C13.0325 10.2279 13.0325 10.4388 12.9023 10.569C12.8372 10.6341 12.752 10.6667 12.6667 10.6667Z"
            fill="#B3B3B3"
        />
    </>
)

const Stopwatch = () => (
    <svg viewBox="0 0 32 32">
        <g>
            <path
                fill="#E56A59"
                d="M12 2h3v2h2V2h3a1 1 0 0 0 0-2h-8a1 1 0 0 0 0 2Z"
            />
            <path
                fill="#E56A59"
                d="M16 6C8.82 6 3 11.82 3 19s5.82 13 13 13 13-5.82 13-13c-.008-7.176-5.824-12.992-13-13Zm7 14h-7a1 1 0 0 1-1-1v-7a1 1 0 0 1 2 0v6h6a1 1 0 0 1 0 2Z"
            />
            <path
                fill="#E56A59"
                d="M28 10a.997.997 0 0 1-.707-.293l-2-2a.999.999 0 1 1 1.414-1.414l2 2A.999.999 0 0 1 28 10Z"
            />
        </g>
    </svg>
)

const Pdf = ()=> <svg  viewBox="0 0 48 48">
    <g >
        <polygon fill="#870000" points="44,27 4,27 1,23 5,19 43,19 47,23 " /> 
        <path fill="#E6E6E6" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z" /> 
        <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z" />
        <path fill="#BD0100" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z" />
        <path fill="#FFFFFF" d="M20.071,30.645c0,0.878-0.259,1.556-0.776,2.033c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122 v-7.853h2.718c0.992,0,1.737,0.217,2.234,0.649C19.822,29.23,20.071,29.847,20.071,30.645z M16.494,31.666h0.387 c0.319,0,0.572-0.09,0.76-0.27c0.188-0.179,0.282-0.426,0.282-0.74c0-0.53-0.293-0.795-0.881-0.795h-0.548V31.666z" />
        <path fill="#FFFFFF" d="M27.988,31.907c0,1.311-0.361,2.32-1.083,3.029C26.184,35.646,25.17,36,23.863,36h-2.541v-7.853h2.718 c1.26,0,2.233,0.322,2.919,0.967C27.645,29.759,27.988,30.689,27.988,31.907z M25.786,31.982c0-0.72-0.142-1.253-0.427-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S25.786,32.752,25.786,31.982z" />
        <path fill="#FFFFFF" d="M31.484,36h-2.089v-7.853h4.641v1.702h-2.551v1.499h2.353v1.702h-2.353V36z" />
    </g>
</svg>

const ActivityTypeIcon = forwardRef(({ name, ...props }, ref) => (
    <svg
        ref={ref}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        {(() => {
            switch (name) {
                case PDF:
                    return <Pdf />
                case WORD:
                    return <Word />
                case EXCEL:
                    return <Excel />
                case POWERPOINT:
                    return <PowerPoint />
                case BROWSER:
                    return <Browser />
                case EVENT:
                    return <Event />
                case PHONE:
                    return <Phone />
                case CORRESPONDENCE:
                    return <Correspondence />
                case OUTLOOK:
                    return <Email />
                case TIMER:
                    return <Stopwatch />
                case OTHER:
                default:
                    return <Question />
            }
        })()}
    </svg>
))

export default ActivityTypeIcon
