import React from 'react'
import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'
import Icon from 'components/util/Icon'

const Input = React.forwardRef(({ mask, ...props }, ref) => {
    if (typeof mask !== 'undefined') {
        return (
            <InputMask mask={mask} {...props}>
                {(inputProps) => (
                    <StyledTextInput
                        ref={ref}
                        disabled={props.disabled}
                        {...inputProps}
                    />
                )}
            </InputMask>
        )
    }
    return <StyledTextInput ref={ref} {...props} />
})

const StyledTextInput = React.forwardRef(
    (
        {
            hasAddonBefore,
            hasAddonAfter,
            hasError = false,
            iconName,
            mask,
            ...props
        },
        ref,
    ) => {
        const iconIsSet = typeof iconName !== 'undefined'
        return (
            <Container ref={ref}>
                <TextInput
                    hasError={hasError}
                    hasAddonBefore={hasAddonBefore}
                    hasAddonAfter={hasAddonAfter}
                    {...props}
                />
                {iconIsSet && <StyledIcon name={iconName} size="18" />}
            </Container>
        )
    },
)

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const TextInput = styled.input`
    width: ${({ width }) => (width ? `${width}rem` : '100%')};
    border-width: ${({ theme }) => theme.borderWidth}rem;
    font-size: ${({ theme }) => theme.defaultFontSize}rem;
    border-color: ${({ theme, hasError }) =>
        `${hasError ? theme.colorRed : theme.defaultBorderColor}`};
    border-radius: ${({ theme }) => theme.defaultBorderRadius}rem;
    ${({ hasAddonBefore }) =>
        hasAddonBefore &&
        css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        `}
    ${({ hasAddonAfter }) =>
        hasAddonAfter &&
        css`
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        `}
    padding: ${({ theme }) => theme.spacingSmaller}rem;
    color: ${({ theme, hasError }) =>
        hasError ? theme.colorRed : theme.colorBlack};
    opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
    background-color: ${({ theme }) => theme.colorWhite};
    :hover {
        border-color: ${({ theme }) => theme.colorGrey};
    }
    ::placeholder {
        color: ${({ theme, hasError }) =>
            hasError ? theme.colorRed : theme.colorGreyLight};
        font-style: italic;
    }
    :disabled {
        background-color: ${({ theme }) => theme.colorGreyLighter};
    }
`

const StyledIcon = styled(Icon)`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => theme.spacingSmall}rem;
    color: ${({ theme }) => theme.colorRed};
`

export default Input
