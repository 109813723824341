import React, { forwardRef } from 'react'
import IconContainer from 'components/util/IconContainer'

const Add = ({ accentColor }) => (
    <g strokeLinecap="round" strokeWidth="2">
        <line fill="none" x1="12" x2="12" y1="7" y2="17" />
        <line fill="none" x1="17" x2="7" y1="12" y2="12" />
        <circle cx="12" cy="12" fill="none" r="11" stroke={accentColor} />
    </g>
)

const AzureActiveDirectory = ({ accentColor }) => (
    <path
        fill={accentColor}
        d="m10.887 2 7.077 6.115L24 18.915h-5.444L10.887 2Zm-.941 1.43 3.02 8.482-5.79 7.25 11.233 1.924H0L9.946 3.43Z"
    />
)

const Alarm = ({ accentColor }) => (
    <>
        <path d="M19,13V8A7,7,0,0,0,5,8v5a13.834,13.834,0,0,1-2,7H21A13.834,13.834,0,0,1,19,13Z" />
        <path d="M9.174,22a3,3,0,0,0,5.652,0Z" stroke={accentColor} />
    </>
)

const Archive = ({ accentColor }) => (
    <>
        <rect height="14" width="20" x="2" y="9" />
        <line stroke={accentColor} x1="5" x2="19" y1="5" y2="5" />
        <line stroke={accentColor} x1="8" x2="16" y1="1" y2="1" />
        <polyline points="16 14 16 16 8 16 8 14" />
    </>
)

const Ban = ({ accentColor }) => (
    <>
        <line
            fill="none"
            stroke="#111111"
            x1="19.8"
            x2="4.2"
            y1="4.2"
            y2="19.8"
        />
        <circle cx="12" cy="12" fill="none" r="11" stroke={accentColor} />
    </>
)

const Billing = ({ accentColor }) => (
    <>
        <path
            d="M1,20v3h12v-2.583 C13,19.036,14.119,18,15.5,18H3C1.895,18,1,18.895,1,20z"
            fill="none"
        />
        <path
            d="M15.5,18c1.294,0,2.5,0.924,2.5,2.5 c0,1.381,1.119,2.5,2.5,2.5h0c1.381,0,2.5-1.119,2.5-2.5V1H7v17"
            fill="none"
        />
        <line fill="none" x1="12" x2="18" y1="7" y2="7" stroke={accentColor} />
        <line
            fill="none"
            x1="12"
            x2="18"
            y1="12"
            y2="12"
            stroke={accentColor}
        />
    </>
)

const Dots = ({ accentColor }) => (
    <>
        <circle cx="12" cy="12" fill={accentColor} r="2" />
        <circle cx="3" cy="12" fill={accentColor} r="2" />
        <circle cx="21" cy="12" fill={accentColor} r="2" />
    </>
)

const DragHandle = () => (
    <g>
        <line fill="none" x1="1" y1="12" x2="23" y2="12" />
        <line fill="none" x1="1" y1="5" x2="23" y2="5" />
        <line fill="none" x1="1" y1="19" x2="23" y2="19" />
    </g>
)

const FolderUser = ({ accentColor }) => (
    <>
        <path
            d="M4,22H3a2,2,0,0,1-2-2V2H9l3,4H23V20a2,2,0,0,1-2,2H20"
            fill="none"
        />
        <circle cx="12" cy="11.5" fill="none" r="2.5" stroke={accentColor} />
        <path
            d="M12,17a5,5,0,0,0-5,5H17A5,5,0,0,0,12,17Z"
            fill="none"
            stroke={accentColor}
        />
    </>
)

const FolderInfo = ({ accentColor }) => (
    <>
        <path
            d="M21,22H3a2,2,0,0,1-2-2V2H9l3,4H23V20A2,2,0,0,1,21,22Z"
            fill="none"
        />
        <line
            fill="none"
            x1="12"
            x2="12"
            y1="14"
            y2="18"
            stroke={accentColor}
        />
        <circle cx="12" cy="10" r="1" stroke="none" fill={accentColor} />
    </>
)

const Lexor = () => (
    <svg>
        <polygon points="22,2 15,21 11,13 3,9 " />
    </svg>
)

const ListNumbers = ({ accentColor }) => (
    <>
        <line fill="none" x1="8" x2="23" y1="4" y2="4" />
        <line fill="none" x1="8" x2="23" y1="12" y2="12" />
        <line fill="none" x1="8" x2="23" y1="20" y2="20" />
        <path
            d="M1.55949,6.59277V5.88135L2.3339,5.81836 c0.12598-0.00879,0.14404-0.04492,0.14404-0.17969V2.49658c0-0.10791-0.02686-0.16211-0.11719-0.18896l-0.7832-0.16211 l0.09912-0.73828h2.16064v4.23145c0,0.14404,0.00879,0.1709,0.14404,0.17969l0.73828,0.06299v0.71143H1.55949z"
            fill={accentColor}
            stroke="none"
            strokeLinecap="square"
        />
        <path
            d="M1.09147,14.6604V13.823l1.20654-1.1792 c0.90918-0.88232,1.29639-1.24219,1.29639-1.75537c0-0.3335-0.16211-0.57617-0.63916-0.57617 c-0.49561,0-0.70264,0.16211-0.70264,0.80127l-1.22412-0.1084C1.03727,9.57397,2.12663,9.3396,3.07194,9.3396 c1.39551,0,1.89062,0.59424,1.89062,1.44043c0,0.84668-0.58496,1.40479-1.24219,2.03467L2.7028,13.78735h1.32373 c0.08984,0,0.12598-0.01807,0.14404-0.1084l0.12598-0.71094h0.9541v1.69238H1.09147z"
            fill={accentColor}
            stroke="none"
            strokeLinecap="square"
        />
        <path
            d="M4.1298,19.90112c0.76562,0.11719,1.18848,0.57617,1.18848,1.38623 c0,1.18848-0.89111,1.74658-2.17871,1.74658c-0.90918,0-1.71924-0.32373-2.17871-0.82812l0.72021-0.79199 c0.34229,0.33301,0.72021,0.59375,1.35059,0.59375c0.50439,0,0.93652-0.17969,0.93652-0.81006 c0-0.54932-0.34229-0.77441-0.90039-0.77441c-0.2251,0-0.396,0.01807-0.6665,0.06299v-0.8999l0.44141-0.0542 c0.64795-0.08105,0.99902-0.396,0.99902-0.97217c0-0.34229-0.15283-0.62109-0.65723-0.62109 c-0.48584,0-0.71973,0.16211-0.71973,0.80127l-1.2334-0.1084c0-1.42236,1.07129-1.66553,2.0166-1.66553 c1.38623,0,1.91748,0.53125,1.91748,1.49463c0,0.75635-0.46826,1.2334-1.03564,1.4043V19.90112z"
            fill={accentColor}
            stroke="none"
            strokeLinecap="square"
        />
    </>
)

const Logout = ({ accentColor }) => (
    <>
        <line fill="none" x1="9" x2="21" y1="12" y2="12" />
        <polyline fill="none" points=" 17,16 21,12 17,8 " />
        <polyline
            fill="none"
            points="19,4 19,1 5,1 5,23 19,23 19,20 "
            stroke={accentColor}
        />
    </>
)

const Microsoft = () => (
    <>
        <rect height="9" width="9" fill="none" x="1" y="1" />
        <rect
            height="9"
            width="9"
            fill="none"
            transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 37 11)"
            x="14"
            y="1"
        />
        <rect height="9" width="9" fill="none" x="1" y="14" />
        <rect
            height="9"
            width="9"
            fill="none"
            transform="matrix(-1 -4.486867e-11 4.486867e-11 -1 37 37)"
            x="14"
            y="14"
        />
    </>
)

const Zoom = ({ accentColor }) => (
    <>
        <line x1="22" x2="15.656" y1="22" y2="15.656" stroke={accentColor} />
        <circle cx="10" cy="10" r="8" />
    </>
)

const Receipt = ({ accentColor }) => (
    <>
        <polygon points="21,23 3,23 3,2 6,4 9,2 12,4 15,2 18,4 21,2" />
        <path
            stroke={accentColor}
            d="M9.048,17 c0.226,1.153,1.223,2,2.452,2h1c1.4,0,2.5-1.1,2.5-2.5l0,0c0-3.5-6-1.5-6-5l0,0C9,10.1,10.1,9,11.5,9h1 c1.228,0,2.225,0.846,2.452,1.999"
        />
        <line stroke={accentColor} x1="12" x2="12" y1="8" y2="20" />
    </>
)

const Stopwatch = ({ accentColor }) => (
    <>
        <polyline stroke={accentColor} points=" 12,10 12,14 16,14 " />
        <circle cx="12" cy="14" r="9" />
        <line stroke={accentColor} x1="9" x2="15" y1="1" y2="1" />
        <line stroke={accentColor} x1="12" x2="12" y1="1" y2="2" />
    </>
)

const Calendar = ({ accentColor }) => (
    <>
        <line stroke={accentColor} x1="17" x2="17" y1="4" y2="1" />
        <line stroke={accentColor} x1="7" x2="7" y1="4" y2="1" />
        <line stroke={accentColor} x1="1" x2="23" y1="8" y2="8" />
        <rect height="18" width="22" x="1" y="4" />
    </>
)

const Download = ({ accentColor }) => (
    <>
        <line fill="none" stroke={accentColor} x1="23" x2="1" y1="15" y2="15" />
        <line fill="none" x1="12" x2="12" y1="1" y2="11" />
        <polyline
            fill="none"
            points="20,6 23,15 23,23 1,23 1,15 4,6 "
            stroke={accentColor}
        />
        <polyline fill="none" points=" 16,7 12,11 8,7 " />
        <line
            fill="none"
            stroke={accentColor}
            x1="19"
            x2="15"
            y1="19"
            y2="19"
        />
    </>
)

const Gear = ({ accentColor }) => (
    <>
        <path
            d="M23,12c0-1.105-0.895-2-2-2 h-1.262c-0.189-0.732-0.477-1.422-0.852-2.058l0.892-0.892c0.781-0.781,0.781-2.047,0-2.828c-0.781-0.781-2.047-0.781-2.828,0 l-0.892,0.892C15.422,4.739,14.732,4.451,14,4.262V3c0-1.104-0.895-2-2-2c-1.105,0-2,0.895-2,2v1.262 C9.268,4.451,8.578,4.739,7.942,5.114L7.05,4.222c-0.781-0.781-2.047-0.781-2.828,0c-0.781,0.781-0.781,2.047,0,2.828l0.892,0.892 C4.739,8.578,4.451,9.268,4.262,10H3c-1.104,0-2,0.895-2,2c0,1.105,0.895,2,2,2h1.262c0.189,0.732,0.477,1.422,0.852,2.058 L4.222,16.95c-0.781,0.781-0.781,2.047,0,2.828c0.781,0.781,2.047,0.781,2.828,0l0.892-0.892c0.635,0.375,1.326,0.663,2.058,0.852 V21c0,1.104,0.895,2,2,2c1.105,0,2-0.895,2-2v-1.262c0.732-0.189,1.422-0.477,2.058-0.852l0.892,0.892 c0.781,0.781,2.047,0.781,2.828,0c0.781-0.781,0.781-2.047,0-2.828l-0.892-0.892c0.375-0.635,0.663-1.326,0.852-2.058H21 C22.104,14,23,13.105,23,12z"
            fill="none"
        />
        <circle cx="12" cy="12" fill="none" r="3" stroke={accentColor} />
    </>
)

const SingleCopy = ({ accentColor }) => (
    <>
        <rect height="18" width="16" x="2" y="5" />
        <polyline points=" 5,1 22,1 22,21 " />
        <line stroke={accentColor} x1="6" x2="14" y1="10" y2="10" />
        <line x1="6" x2="14" y1="14" y2="14" />
        <line x1="6" x2="10" y1="18" y2="18" />
    </>
)

const SingleCopies = ({ accentColor }) => (
    <>
        <rect
            height="14"
            width="12"
            fill="none"
            stroke={accentColor}
            x="2"
            y="9"
        />
        <polyline fill="none" points=" 6,5 18,5 18,19 " />
        <polyline fill="none" points=" 10,1 22,1 22,15 " />
    </>
)

const Comment = () => (
    <path d="M20.4,16.8 C22,15.2,23,13.2,23,11c0-5-4.9-9-11-9S1,6,1,11c0,5,4.9,9,11,9c1.1,0,2.1-0.1,3.1-0.4L21,22L20.4,16.8z" />
)

const ConstructionSign = ({ accentColor }) => (
    <>
        <line x1="9" x2="9" y1="21" y2="30" />
        <line x1="5" x2="5" y1="30" y2="21" />
        <line x1="27" x2="27" y1="21" y2="30" />
        <line x1="23" x2="23" y1="30" y2="21" />
        <line x1="2" x2="12" y1="30" y2="30" />
        <line x1="20" x2="30" y1="30" y2="30" />
        <polyline points="9 5 9 1 5 1 5 5" />
        <polyline points="27 5 27 1 23 1 23 5" />
        <line stroke={accentColor} x1="5" x2="17" y1="5" y2="17" />
        <line stroke={accentColor} x1="1" x2="11" y1="7" y2="17" />
        <line stroke={accentColor} x1="21" x2="31" y1="5" y2="15" />
        <line stroke={accentColor} x1="15" x2="27" y1="5" y2="17" />
        <rect height="12" width="30" x="1" y="5" />
    </>
)

const Check = () => <polyline fill="none" points="2,12 9,19 22,6 " />

const ChevronDown = () => <polyline points="1,8 12,16 23,8 " />

const ChevronUp = () => <polyline points="23,16 12,8 1,16 " />

const ChevronLeft = () => <polyline points="16,1 8,12 16,23 " />

const ChevronRight = () => <polyline points="8,1 16,12 8,23 " />

const TrashCan = ({ accentColor }) => (
    <>
        <path
            d="M20,9l-.867,12.142A2,2,0,0,1,17.138,23H6.862a2,2,0,0,1-1.995-1.858L4,9"
            fill="none"
            stroke={accentColor}
        />
        <line fill="none" x1="1" x2="23" y1="5" y2="5" />
        <path d="M8,5V2A1,1,0,0,1,9,1h6a1,1,0,0,1,1,1V5" fill="none" />
    </>
)

const Cross = () => (
    <>
        <line x1="19" x2="5" y1="5" y2="19" />
        <line x1="19" x2="5" y1="19" y2="5" />
    </>
)

const Office = ({ accentColor }) => (
    <>
        <polyline points=" 18,6 18,1 6,1 6,9 " />
        <line stroke={accentColor} x1="10" x2="14" y1="23" y2="23" />
        <rect stroke={accentColor} height="11" width="9" x="1" y="12" />
        <rect stroke={accentColor} height="14" width="9" x="14" y="9" />
        <line stroke={accentColor} x1="18" x2="19" y1="13" y2="13" />
        <line stroke={accentColor} x1="18" x2="19" y1="16" y2="16" />
        <line stroke={accentColor} x1="18" x2="19" y1="19" y2="19" />
        <line stroke={accentColor} x1="5" x2="6" y1="19" y2="19" />
        <line stroke={accentColor} x1="5" x2="6" y1="16" y2="16" />
    </>
)

const Single = ({ accentColor }) => (
    <>
        <path d="M12,12L12,12 c-2.761,0-5-2.239-5-5V6c0-2.761,2.239-5,5-5h0c2.761,0,5,2.239,5,5v1C17,9.761,14.761,12,12,12z" />
        <path
            stroke={accentColor}
            d="M22,20.908 c0-1.8-1.197-3.383-2.934-3.856C17.172,16.535,14.586,16,12,16s-5.172,0.535-7.066,1.052C3.197,17.525,2,19.108,2,20.908V23h20 V20.908z"
        />
    </>
)

const Palette = ({ accentColor }) => (
    <>
        <path
            d="M1 12C1 8.3 2.8 5 5.7 3C6.7 2.3 8.4 2.1 9 3C10 4.2 8.2 6 9 7C11 9.4 15.1 4.7 20 7C23.5 8.8 23 13.3 22.6 15C21.3 19.6 17 23 12 23C5.9 23 1 18.1 1 12Z"
            stroke="#2D3748"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M17 14C18.1046 14 19 13.1046 19 12C19 10.8954 18.1046 10 17 10C15.8954 10 15 10.8954 15 12C15 13.1046 15.8954 14 17 14Z"
            stroke={accentColor}
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z"
            stroke="#228BE6"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M7.5 18C8.05228 18 8.5 17.5523 8.5 17C8.5 16.4477 8.05228 16 7.5 16C6.94772 16 6.5 16.4477 6.5 17C6.5 17.5523 6.94772 18 7.5 18Z"
            stroke="#FAB005"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 20C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18C12.4477 18 12 18.4477 12 19C12 19.5523 12.4477 20 13 20Z"
            stroke="#40C057"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </>
)

const Plus = () => (
    <>
        <line x1="12" x2="12" y1="2" y2="22" />
        <line x1="22" x2="2" y1="12" y2="12" />
    </>
)

const Pricing = ({ accentColor }) => (
    <>
        <circle cx="17.5" cy="9.5" fill="none" r="2.5" stroke={accentColor} />
        <circle cx="9.5" cy="3.5" fill="none" r="2.5" stroke={accentColor} />
        <path
            d="M13.476,16H19c2.817,0,3,2,3,2 l-11.765,4.525c-0.787,0.303-1.665,0.26-2.419-0.117L1,19v-7h1c1.105,0,3.906,0.438,5,2l0,0h3c2.209,0,4,1.791,4,4l0,0H7"
            fill="none"
        />
    </>
)

const Survey = ({ accentColor }) => (
    <>
        <line fill="none" x1="12" x2="23" y1="6" y2="6" />
        <rect height="6" width="6" fill="none" x="2" y="3" />
        <line
            fill="none"
            x1="12"
            x2="23"
            y1="18"
            y2="18"
            stroke={accentColor}
        />
        <line fill="none" x1="2" x2="8" y1="15" y2="21" stroke={accentColor} />
        <line fill="none" x1="8" x2="2" y1="15" y2="21" stroke={accentColor} />
    </>
)

const Edit = ({ accentColor }) => (
    <>
        <line fill="none" x1="14.328" x2="19.328" y1="4.672" y2="9.672" />
        <path
            d="M8,21,2,22l1-6L16.414,2.586a2,2,0,0,1,2.828,0l2.172,2.172a2,2,0,0,1,0,2.828Z"
            fill="none"
            stroke={accentColor}
        />
    </>
)

const ArrowLeft = ({ accentColor }) => (
    <>
        <line fill="none" x1="22" x2="2" y1="12" y2="12" />
        <polyline fill="none" points="9,19 2,12 9,5 " stroke={accentColor} />
    </>
)

const ArrowRight = ({ accentColor }) => (
    <>
        <line fill="none" x1="2" x2="22" y1="12" y2="12" />
        <polyline fill="none" points="15,5 22,12 15,19 " stroke={accentColor} />
    </>
)

const Circle = ({ accentColor }) => (
    <circle
        cx="12"
        cy="12"
        r="6"
        stroke={accentColor}
        strokeWidth="2"
        fill={accentColor}
    />
)

const LaunchApp = ({ accentColor }) => (
    <>
        <line fill="none" x1="12" x2="23" y1="12" y2="1" />
        <polyline fill="none" points=" 15,1 23,1 23,9 " />
        <polyline
            fill="none"
            points="9,1 1,1 1,23 23,23 23,15 "
            strokeWidth="2"
            stroke={accentColor}
        />
    </>
)

const List = () => (
    <g strokeLinecap="square" strokeWidth="2">
        <line fill="none" x1="1" x2="23" y1="12" y2="12" />
        <line fill="none" x1="1" x2="23" y1="5" y2="5" />
        <line fill="none" x1="1" x2="23" y1="19" y2="19" />
    </g>
)

const MenuRight = ({ accentColor }) => (
    <>
        <line fill="none" x1="1" x2="23" y1="12" y2="12" />
        <line fill="none" x1="1" x2="23" y1="5" y2="5" />
        <line
            fill="none"
            stroke={accentColor}
            x1="12"
            x2="23"
            y1="19"
            y2="19"
        />
    </>
)

const MoveRight = ({ accentColor }) => (
    <>
        <line fill="none" stroke={accentColor} x1="7" x2="22" y1="12" y2="12" />
        <polyline fill="none" points="16,6 22,12 16,18 " stroke={accentColor} />
        <line fill="none" x1="2" x2="2" y1="2" y2="22" />
    </>
)

const MoneyCoins = ({ accentColor }) => (
    <>
        <polyline fill="none" points="7,13 1,13 1,1 19,1 19,9.171 " />
        <path
            d="M11,12v4c0,1.657,2.686,3,6,3 s6-1.343,6-3v-4"
            fill="none"
            stroke={accentColor}
        />
        <path
            d="M11,16v4c0,1.657,2.686,3,6,3 s6-1.343,6-3v-4"
            fill="none"
            stroke={accentColor}
        />
        <ellipse
            cx="17"
            cy="12"
            fill="none"
            rx="6"
            ry="3"
            stroke={accentColor}
        />
        <circle cx="10" cy="7" fill="none" r="1" />
        <circle cx="10" cy="7" r="1" stroke="none" strokeLinecap="square" />
    </>
)

const Subscription = ({ accentColor }) => (
    <>
        <polygon
            points="3 1 21 1 21 22 18 20 15 22 12 20 9 22 6 20 3 22 3 1"
            strokeMiterlimit="10"
        />
        <line
            x1="7"
            y1="15"
            x2="12"
            y2="15"
            strokeMiterlimit="10"
            stroke={accentColor}
        />
        <line
            x1="16"
            y1="15"
            x2="17"
            y2="15"
            strokeMiterlimit="10"
            stroke={accentColor}
        />
        <circle
            cx="12"
            cy="8"
            r="3"
            strokeMiterlimit="10"
            stroke={accentColor}
        />
    </>
)

const Tags = ({ accentColor }) => (
    <>
        <path
            d="M13.243,22.414l5.171-5.171a2,2,0,0,0,0-2.829L9,5H1v8l9.414,9.414A2,2,0,0,0,13.243,22.414Z"
            fill="none"
            stroke={accentColor}
        />
        <polyline fill="none" points="21 11 11 1 3 1" />
        <circle cx="6" cy="10" r="2" stroke="none" fill={accentColor} />
    </>
)

const Users = ({ accentColor }) => (
    <>
        <path
            d="M19,21h4V16.677a1,1,0,0,0-.629-.928l-3.742-1.5A1,1,0,0,1,18,13.323v-.878A3.982,3.982,0,0,0,20,9V7a4,4,0,0,0-6-3.465"
            fill="none"
            stroke={accentColor}
        />
        <path
            d="M14.371,16.749l-3.742-1.5A1,1,0,0,1,10,14.323v-.451C13.27,13.435,14,12,14,12s-2-2-2-4A4,4,0,0,0,4,8c0,2-2,4-2,4s.73,1.435,4,1.872v.451a1,1,0,0,1-.629.928l-3.742,1.5A1,1,0,0,0,1,17.677V21H15V17.677A1,1,0,0,0,14.371,16.749Z"
            fill="none"
        />
    </>
)

const Warning = ({ accentColor }) => (
    <>
        <circle cx="12" cy="21" fill="none" r="1" />
        <circle cx="12" cy="21" r="1" stroke="none" strokeLinecap="square" />
        <line fill="none" stroke={accentColor} x1="12" x2="12" y1="2" y2="15" />
    </>
)

const Pdf = ()=> <svg  viewBox="0 0 48 48">
    <g >
        <polygon fill="#870000" points="44,27 4,27 1,23 5,19 43,19 47,23 " /> 
        <path fill="#E6E6E6" d="M41,47H7c-1.105,0-2-0.895-2-2V3c0-1.105,0.895-2,2-2l24,0l12,12v32C43,46.105,42.105,47,41,47z" /> 
        <path fill="#B3B3B3" d="M31,1v10c0,1.105,0.895,2,2,2h10L31,1z" />
        <path fill="#BD0100" d="M45,41H3c-1.105,0-2-0.895-2-2V23h46v16C47,40.105,46.105,41,45,41z" />
        <path fill="#FFFFFF" d="M20.071,30.645c0,0.878-0.259,1.556-0.776,2.033c-0.518,0.479-1.252,0.717-2.205,0.717h-0.596V36h-2.122 v-7.853h2.718c0.992,0,1.737,0.217,2.234,0.649C19.822,29.23,20.071,29.847,20.071,30.645z M16.494,31.666h0.387 c0.319,0,0.572-0.09,0.76-0.27c0.188-0.179,0.282-0.426,0.282-0.74c0-0.53-0.293-0.795-0.881-0.795h-0.548V31.666z" />
        <path fill="#FFFFFF" d="M27.988,31.907c0,1.311-0.361,2.32-1.083,3.029C26.184,35.646,25.17,36,23.863,36h-2.541v-7.853h2.718 c1.26,0,2.233,0.322,2.919,0.967C27.645,29.759,27.988,30.689,27.988,31.907z M25.786,31.982c0-0.72-0.142-1.253-0.427-1.601 c-0.284-0.348-0.717-0.521-1.297-0.521h-0.618v4.398h0.473c0.645,0,1.117-0.188,1.418-0.562S25.786,32.752,25.786,31.982z" />
        <path fill="#FFFFFF" d="M31.484,36h-2.089v-7.853h4.641v1.702h-2.551v1.499h2.353v1.702h-2.353V36z" />
    </g>
</svg>

const BannerWarning = ({accentColor}) => (
    <svg viewBox="0 0 48 48">
        <g>
            <path
                d="M45.521,39.04L27.527,5.134c-1.021-1.948-3.427-2.699-5.375-1.679-.717,.376-1.303,.961-1.679,1.679L2.479,39.04c-.676,1.264-.635,2.791,.108,4.017,.716,1.207,2.017,1.946,3.42,1.943H41.993c1.403,.003,2.704-.736,3.42-1.943,.743-1.226,.784-2.753,.108-4.017ZM23.032,15h1.937c.565,0,1.017,.467,1,1.031l-.438,14c-.017,.54-.459,.969-1,.969h-1.062c-.54,0-.983-.429-1-.969l-.438-14c-.018-.564,.435-1.031,1-1.031Zm.968,25c-1.657,0-3-1.343-3-3s1.343-3,3-3,3,1.343,3,3-1.343,3-3,3Z"
                fill={accentColor}
                stroke={accentColor}
            />
        </g>
    </svg>
)

const OCheck = ({ accentColor }) => (
    <polygon
        fill="none"
        points="2,11 4,9 9,12 20,4 22,6 9,19 "
        stroke={accentColor}
    />
)

const Help = ({ accentColor }) => (
    <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(0.5 0.5)"
        fill={accentColor}
        stroke={accentColor}
    >
        <circle
            cx="12"
            cy="12"
            r="11"
            fill="none"
            stroke="#1a202c"
            strokeMiterlimit="10"
        />
        <circle data-stroke="none" cx="12" cy="18" r="1" stroke="none" />
        <path
            d="M9.853,6.56c1.98-.875,4.542-.771,5.417.645s.271,3.063-1.229,4.334S12,13.5,12,14.5"
            fill="none"
            strokeMiterlimit="10"
        />
    </g>
)

const Dashboard = () => (
    <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
        transform="translate(0.5 0.5)"
        fill="#e56a59"
        stroke="#e56a59"
    >
        <line x1="7" y1="21" x2="7" y2="8" fill="none" strokeMiterlimit="10" />{' '}
        <line
            x1="12"
            y1="21"
            x2="12"
            y2="13"
            fill="none"
            stroke="#e56a59"
            strokeMiterlimit="10"
        />
        <line
            x1="17"
            y1="21"
            x2="17"
            y2="8"
            fill="none"
            strokeMiterlimit="10"
        />{' '}
        <line
            x1="22"
            y1="21"
            x2="22"
            y2="13"
            fill="none"
            stroke="#e56a59"
            strokeMiterlimit="10"
        />
        <line
            x1="2"
            y1="21"
            x2="2"
            y2="3"
            fill="none"
            stroke="#e56a59"
            strokeMiterlimit="10"
        />
    </g>
)

const HeadPhone = () => (
    <g
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        transform="translate(0.5 0.5)"
    >
        <path
            fill="none"
            strokeMiterlimit="10"
            d="M4,17.584V19 c0,2.209,1.791,4,4,4h4"
        />
        <path
            fill="none"
            strokeMiterlimit="10"
            d="M4,8.406V8c0-3.866,3.134-7,7-7 h2c3.866,0,7,3.134,7,7v0.422"
        />
        <path
            fill="none"
            stroke="#e56a59"
            strokeMiterlimit="10"
            d="M7,8.101 C6.677,8.035,6.343,8,6,8c-2.761,0-5,2.239-5,5s2.239,5,5,5c0.343,0,0.677-0.035,1-0.101V8.101z"
        />
        <path
            fill="none"
            stroke="#e56a59"
            strokeMiterlimit="10"
            d="M17,17.899 C17.323,17.965,17.657,18,18,18c2.761,0,5-2.239,5-5s-2.239-5-5-5c-0.343,0-0.677,0.035-1,0.101V17.899z"
        />
    </g>
)

const Fields = ({ accentColor }) => (
    <g stroke="none" fill={accentColor}>
        <path d="M2.5 4v3h5v12h3V7h5V4h-13zm19 5h-9v3h3v7h3v-7h3V9z" />
    </g>
)

const Badge = ({ accentColor }) => (
    <g strokeLinecap="round" strokeWidth="1.5" transform="translate(0.5 0.5)">
        <polyline
            fill="none"
            points="14,6 23,6 23,23 1,23 1,6 10,6 "
            stroke={accentColor}
        />
        <path
            d="M14,8h-4V3 c0-1.105,0.895-2,2-2h0c1.105,0,2,0.895,2,2V8z"
            fill="none"
            stroke={accentColor}
        />
        <line fill="none" x1="15" x2="19" y1="13" y2="13" />
        <line fill="none" x1="15" x2="19" y1="17" y2="17" />
        <path
            d="M4,19c0-1.657,1.343-3,3-3h2c1.657,0,3,1.343,3,3H4z"
            stroke="none"
            strokeLinecap="square"
        />
        <circle cx="8" cy="13" r="2" stroke="none" strokeLinecap="square" />
    </g>
)

const Role = ({ accentColor }) => (
    <g strokeLinecap="round" strokeWidth="1.5" transform="translate(0.5 0.5)">
        <path
            d="M9.455,18.086l-3.243,1.394 C5.477,19.796,5,20.519,5,21.319V23h14v-1.681c0-0.8-0.477-1.523-1.212-1.838l-3.251-1.388"
            fill="none"
            stroke={accentColor}
        />
        <circle cx="12" cy="3" fill="none" r="2" />
        <circle cx="21" cy="8" fill="none" r="2" />
        <circle cx="3" cy="8" fill="none" r="2" />
        <path
            d="M12,19L12,19 c-2.209,0-4-1.791-4-4v-1c0-2.209,1.791-4,4-4h0c2.209,0,4,1.791,4,4v1C16,17.209,14.209,19,12,19z"
            fill="none"
            stroke={accentColor}
        />
    </g>
)

const Api = () => (
    <g strokeLinecap="round" strokeWidth="1.2" transform="translate(0.5 0.5)">
        <line fill="none" x1="2" x2="4.464" y1="22" y2="19.536" />
        <path
            d="M5.964,10.964l-1.5,1.5a5,5,0,0,0,7.072,7.072l1.5-1.5Z"
            fill="none"
        />
        <line fill="none" x1="22" x2="19.536" y1="2" y2="4.464" />
        <path
            d="M18.036,13.036l1.5-1.5a5,5,0,0,0-7.072-7.072l-1.5,1.5Z"
            fill="none"
        />
        <line fill="none" x1="10" x2="8" y1="11" y2="13" />
        <line fill="none" x1="13" x2="11" y1="14" y2="16" />
    </g>
)

const Clock = ({ accentColor }) => (
    <g>
        <path
            d="M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M19,13h-8V5h2v6h6V13z"
            fill="none"
            stroke={accentColor}
        />
    </g>
)

const Start = () => (
    <g width="24" height="24">
        <path
            fill="currentColor"
            d="m11.741 5.562-10-5.5a.5.5 0 0 0-.5.008A.5.5 0 0 0 1 .5v11a.5.5 0 0 0 .246.43.491.491 0 0 0 .254.07.5.5 0 0 0 .241-.062l10-5.5a.5.5 0 0 0 0-.876Z"
        />
    </g>
)
const Stop = () => (
    <g width="24" height="24">
        <path
            fill="currentColor"
            d="M10 0H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z"
        />
    </g>
)
const Pause = () => (
    <svg width="24" height="24">
        <g fill="currentColor">
            <rect width="4" height="12" x="8" rx=".5" />
            <rect width="4" height="12" rx=".5" />
        </g>
    </svg>
)

const AccountLogin = ({ accentColor }) => 
    <svg  height="17" width="17" viewBox="0 0 48 48">
        <g>
            <path fill={accentColor} d="M38,1H10A5.006,5.006,0,0,0,5,6v8a1,1,0,0,0,2,0V6a3,3,0,0,1,3-3H38a3,3,0,0,1,3,3V42a3,3,0,0,1-3,3H10a3,3,0,0,1-3-3V34a1,1,0,0,0-2,0v8a5.006,5.006,0,0,0,5,5H38a5.006,5.006,0,0,0,5-5V6A5.006,5.006,0,0,0,38,1Z" />
            <path fill={accentColor} d="M20.546,32.891a1,1,0,0,0,1.042-.082l11-8a1,1,0,0,0,0-1.618l-11-8A1,1,0,0,0,20,16v6H7a2,2,0,0,0,0,4H20v6A1,1,0,0,0,20.546,32.891Z"/>
        </g>
    </svg>

const IconMedium = forwardRef(({ size, accentColor, name, ...props }, ref) => ( 
    <IconContainer
        name={name}
        size={size}
        viewBox="0 0 24 24"
        ref={ref}
        accentColor={accentColor}
        {...props}
    >
        {(accentColor) => {
            const iconProps = { accentColor }

            switch (name) {
                case 'pdf':
                    return <Pdf {...iconProps} />
                case 'accountLogin':
                    return <AccountLogin {...iconProps}/>
                case 'stopTimer':
                    return <Stop />
                case 'pauseTimer':
                    return <Pause />
                case 'starTimer':
                    return <Start />
                case 'add':
                    return <Add {...iconProps} />
                case 'azure-activy-directory':
                    return <AzureActiveDirectory {...iconProps} />
                case 'ban':
                    return <Ban {...iconProps} />
                case 'billing':
                    return <Billing {...iconProps} />
                case 'lexor':
                    return <Lexor {...iconProps} />
                case 'numbers':
                    return <ListNumbers {...iconProps} />
                case 'logout':
                    return <Logout {...iconProps} />
                case 'alarm':
                    return <Alarm {...iconProps} />
                case 'delete':
                    return <TrashCan {...iconProps} />
                case 'dots':
                    return <Dots {...iconProps} />
                case 'download':
                    return <Download {...iconProps} />
                case 'drag-handle':
                    return <DragHandle {...iconProps} />
                case 'folder-user':
                case 'project-partner-link-role':
                    return <FolderUser {...iconProps} />
                case 'folder-info':
                case 'project-procedure':
                    return <FolderInfo {...iconProps} />
                case 'settings':
                    return <Gear {...iconProps} />
                case 'invoice':
                    return <Receipt {...iconProps} />
                case 'stopwatch':
                    return <Stopwatch {...iconProps} />
                case 'calendar':
                    return <Calendar {...iconProps} />
                case 'documents':
                case 'copy':
                    return <SingleCopy {...iconProps} />
                case 'group':
                    return <SingleCopies {...iconProps} />
                case 'contacts':
                    return <Comment {...iconProps} />
                case 'projects':
                    return <Archive {...iconProps} />
                case 'microsoft':
                    return <Microsoft {...iconProps} />
                case 'coins':
                    return <MoneyCoins {...iconProps} />
                case 'construction':
                    return <ConstructionSign {...iconProps} />
                case 'chevronDown':
                    return <ChevronDown {...iconProps} />
                case 'chevronUp':
                    return <ChevronUp {...iconProps} />
                case 'chevronRight':
                    return <ChevronRight {...iconProps} />
                case 'chevronLeft':
                    return <ChevronLeft {...iconProps} />
                case 'remove':
                case 'cross':
                    return <Cross {...iconProps} />
                case 'institution':
                case 'company':
                case 'office':
                    return <Office {...iconProps} />
                case 'palette':
                    return <Palette {...iconProps} />
                case 'pricing':
                    return <Pricing {...iconProps} />
                case 'person':
                case 'coworker':
                    return <Single {...iconProps} />
                case 'plus':
                    return <Plus {...iconProps} />
                case 'survey':
                    return <Survey {...iconProps} />
                case 'arrowLeft':
                    return <ArrowLeft {...iconProps} />
                case 'arrowRight':
                    return <ArrowRight {...iconProps} />
                case 'circle':
                    return <Circle {...iconProps} />
                case 'search':
                    return <Zoom {...iconProps} />
                case 'subscription':
                    return <Subscription {...iconProps} />
                case 'support':
                    return <HeadPhone {...iconProps} />
                case 'openInTab':
                    return <LaunchApp {...iconProps} />
                case 'pencil':
                case 'edit':
                    return <Edit {...iconProps} />
                case 'check':
                case 'save':
                    return <Check {...iconProps} />
                case 'tags':
                    return <Tags {...iconProps} />
                case 'users':
                    return <Users {...iconProps} />
                case 'warning':
                    return <Warning {...iconProps} />
                case 'bannerWarning':
                    return <BannerWarning {...iconProps} />
                case 'ocheck':
                    return <OCheck {...iconProps} />
                case 'menuRight':
                    return <MenuRight {...iconProps} />
                case 'moveRight':
                    return <MoveRight {...iconProps} />
                case 'help':
                    return <Help {...iconProps} />
                case 'list':
                    return <List {...iconProps} />
                case 'dashboard':
                    return <Dashboard {...iconProps} />
                case 'fields':
                    return <Fields {...iconProps} />
                case 'badge':
                    return <Badge {...iconProps} />
                case 'role':
                    return <Role {...iconProps} />
                case 'api':
                    return <Api {...iconProps} />
                case 'clock':
                    return <Clock {...iconProps} />
                default:
                    throw Error(
                        `Icon with name '${name}' was not found in IconMedium.`,
                    )
            }
        }}
    </IconContainer>
))

export default IconMedium
