import gql from 'graphql-tag'
import { useApolloClient } from '@apollo/client'
import { useQuery } from 'components/hooks/useApolloQueries'
import { PARTNER_TYPE_COWORKER } from 'util/constants'
import { createQueryUpdater } from 'util/graphql'
import { ASC } from 'components/util/TableSorting'
import { TENANT_SETTINGS_QUERY } from 'components/hooks/useTenantSettings'
import SettingsCoworkersActionsPopover from './SettingsCoworkersActionsPopover'

export const COWORKER_FRAGMENT = gql`
    fragment Coworker on Partner {
        id
        initials
        fullName
        name
        firstName
        createdOn
        isActive
        projectPartnerLinksCount
        loginEmail
        preferredCulture {
            id
            slug
            translation
        }
        permissionRole {
            id
            slug
            translation
        }
        mergeChildrenPartners {
            id
            fullName
            externalApplications {
                id
                name
            }
        }
        permissions {
            ...SettingsCoworkersActionsPopover
        }
    }
    ${SettingsCoworkersActionsPopover.FRAGMENT}
`

const SETTINGS_COWORKERS_PAGE_QUERY = gql`
    query settingsCoworkersPageQuery(
        $coworkerWhere: NewPartnerWhereInput
        $externalCoworkerWhere: NewPartnerWhereInput
        $orderBy: NewPartnerOrderInput
        $search: PartnerSearchInput
        $currentPage: Int!
        $pageSize: Int!
    ) {
        coworkers: partnersPaginated(
            currentPage: $currentPage
            pageSize: $pageSize
            where: $coworkerWhere
            orderBy: $orderBy
        ) {
            nodes {
                ...Coworker
            }
        }
        externalCoworkers: partnersPaginated(
            currentPage: $currentPage
            pageSize: $pageSize
            where: $externalCoworkerWhere
            orderBy: $orderBy
            search: $search
        ) {
            nodes {
                id
                fullName
                mergeParentPartnerId
                externalApplicationIds
                externalApplications {
                    id
                    name
                }
            }
        }
        externalApplications(currentPage: $currentPage, pageSize: $pageSize) {
            nodes {
                id
                name
            }
        }
    }
    ${COWORKER_FRAGMENT}
`

const EXTERNAL_COWORKERS_QUERY = gql`
    query externalCoworkersQuery(
        $externalCoworkerWhere: NewPartnerWhereInput
        $search: PartnerSearchInput
        $currentPage: Int!
        $pageSize: Int!
    ) {
        externalCoworkers: partnersPaginated(
            currentPage: $currentPage
            pageSize: $pageSize
            where: $externalCoworkerWhere
            search: $search
        ) {
            nodes {
                id
                fullName
                mergeParentPartnerId
                externalApplicationIds
                externalApplications {
                    id
                    name
                }
            }
        }
    }
`

const useFetchSettingsCoworkersPageData = (pageSize, currentPage, fullNameContains) => {
    const apolloClient = useApolloClient()
    const variables = {
        pageSize,
        currentPage,
        orderBy: {
            fullName: ASC,
        },
        search: {
            fullNameContains,
        },
        coworkerWhere: {
            typeSlugs: [PARTNER_TYPE_COWORKER],
            includeInactive: true,
            isLinkedToAccount: true,
        },
        externalCoworkerWhere: {
            typeSlugs: [PARTNER_TYPE_COWORKER],
            includeInactive: true,
            isLinkedToExternalApplication: true,
        },
    }

    const {
        data = {},
        error,
        loading,
    } = useQuery(SETTINGS_COWORKERS_PAGE_QUERY, {
        variables,
    })

    const {
        refetch: refetchExternalCoworkers
    } = useQuery(EXTERNAL_COWORKERS_QUERY, {
        variables,
        skip: true, // This ensures the query doesn't run automatically
    })

    const coworkersQueryUpdater = {
        remove: (path, id) =>
            createQueryUpdater(
                apolloClient,
                SETTINGS_COWORKERS_PAGE_QUERY,
                variables,
            )(path, (cachedCoworkers) =>
                cachedCoworkers.filter(({ node }) => node.id !== id),
            ),
        replace: (path, coworker) =>
            createQueryUpdater(
                apolloClient,
                SETTINGS_COWORKERS_PAGE_QUERY,
                variables,
            )(path, (cachedCoworkers) =>
                cachedCoworkers.map((coworkerNode) =>
                    coworkerNode.node.id === coworker.id
                        ? {
                              node: coworker,
                              __typename: 'PartnerEdge',
                          }
                        : coworkerNode,
                ),
            ),
    }

    const settingCoworkersPageRefetchQueries = [
        {
            query: SETTINGS_COWORKERS_PAGE_QUERY,
            variables,
        },
        {
            query: TENANT_SETTINGS_QUERY,
        },
    ]

    if (loading) {
        return {
            error,
            coworkers: [],
            externalApplicationCoworkers: [],
            externalApplications: [],
            isFetching: loading,
            coworkersQueryUpdater,
            settingCoworkersPageRefetchQueries,
            refetchExternalCoworkers, // Return the refetch function for external coworkers
        }
    }

    return {
        error,
        coworkers: data.coworkers.nodes,
        externalApplicationCoworkers: data.externalCoworkers.nodes,
        externalApplications: data.externalApplications.nodes,
        isFetching: loading,
        coworkersQueryUpdater,
        settingCoworkersPageRefetchQueries,
        refetchExternalCoworkers, // Return the refetch function for external coworkers
    }
}

export default useFetchSettingsCoworkersPageData
