export const APP_NAME = 'Lexor'

export const BILL_ENTRY_TYPE_COST = 'cost'
export const BILL_ENTRY_TYPE_OTHER = 'other'
export const BILL_ENTRY_TYPE_ACTIVITY = 'activity'

export const PARTNER_TYPE_PERSON = 'person'
export const PARTNER_TYPE_COMPANY = 'company'
export const PARTNER_TYPE_COWORKER = 'coworker'

export const PARTNER_LINK_ROLE_EMPLOYER = 'employer'
export const PARTNER_LINK_ROLE_EMPLOYEE = 'employee'
export const PARTNER_LINK_ROLE_HUSBAND = 'husband'
export const PARTNER_LINK_ROLE_WIFE = 'wife'
export const PARTNER_LINK_ROLE_COLLEAGUE = 'colleague'

export const PROJECT_PARTNER_LINK_ROLE_LAWYER = 'lawyer'
export const PROJECT_PARTNER_LINK_ROLE_EXPERT = 'expert'
export const PROJECT_PARTNER_LINK_ROLE_CLIENT = 'client'
export const PROJECT_PARTNER_LINK_ROLE_CONTACT_PERSON = 'contact-person'
export const PROJECT_PARTNER_LINK_ROLE_LAWYER_OFFICE = 'lawyer-office'
export const PROJECT_PARTNER_LINK_ROLE_MANGER = 'manager'
export const PROJECT_PARTNER_LINK_ROLE_APPLICANT = 'applicant'
export const PROJECT_PARTNER_LINK_ROLE_PARTNERSHIP = 'partnership'
export const PROJECT_PARTNER_LINK_ROLE_COURT = 'court'
export const PROJECT_PARTNER_LINK_ROLE_CORRESPONDENT = 'correspondent'
export const PROJECT_PARTNER_LINK_ROLE_INTERMEDIARY = 'intermediary'
export const PROJECT_PARTNER_LINK_ROLE_DEBT_COLLECTOR = 'debt-collector'
export const PROJECT_PARTNER_LINK_ROLE_OTHER = 'other'

export const NUMBERING_DEFINITION_CUSTOM = 'custom'

export const TYPE_ERROR = 'error'
export const TYPE_WARNING = 'warning'
export const TYPE_SUCCESS = 'success'
export const TYPE_MESSAGE = 'message'

export const FIXED_COST_ENTRY_TYPE = 'fixed'
export const PRICE_PER_UNIT_COST_ENTRY_TYPE = 'pricePerUnit'

export const NUMBERING_SECTION_TYPE_DATE = 'date'
export const NUMBERING_SECTION_TYPE_STRING = 'string'
export const NUMBERING_SECTION_TYPE_INCREMENTAL = 'incremental'

export const ACCOUNT_STATE_NEW = 'new'
export const ACCOUNT_STATE_LOADING = 'loading'
export const ACCOUNT_STATE_ONBOARDING = 'onboarding'
export const ACCOUNT_STATE_INVITED = 'invited'
export const ACCOUNT_STATE_READY = 'ready'
export const ACCOUNT_STATE_SUSPENDED = 'suspended'

export const ACCOUNT_STATE_DETAILS_UNPAID = 'unpaid'
export const ACCOUNT_STATE_DETAILS_DEACTIVATED = 'deactivated'

export const SUBSCRIPTION_INTERVAL_MONTHLY = 'month'
export const SUBSCRIPTION_INTERVAL_YEARLY = 'year'

export const PAYMENT_TYPE_CREDITCARD = 'card'
export const PAYMENT_TYPE_SEPA = 'sepa_debit'

export const ACTIVITIY_TYPE_OTHER = 'other'
export const ACTIVITIY_TYPE_CORRESPONDENCE = 'correspondence'
export const ACTIVITIY_TYPE_EVENT = 'event'
export const ACTIVITIY_TYPE_PHONE = 'phone'
export const ACTIVITIY_TYPE_WORD = 'word'
export const ACTIVITIY_TYPE_EXCEL = 'excel'
export const ACTIVITIY_TYPE_POWERPOINT = 'powerpoint'
export const ACTIVITIY_TYPE_BROWSER = 'browser'
export const ACTIVITIY_TYPE_OUTLOOK = 'outlook'
export const ACTIVITIY_TYPE_TIMER = 'timer'
export const ACTIVITIY_TYPE_PDF = 'PDF'

export const DAY_VIEW = 'timeGridDay'
export const WEEK_VIEW = 'timeGridWeek'

export const LIST_VIEW = 'list'
export const CALENDAR_VIEW = 'calendar'

export const ACTIVITY_STATUS_PROPOSED = 'proposed'
export const ACTIVITY_STATUS_APPROVED = 'approved'
export const ACTIVITY_STATUS_CONFIRMED = 'confirmed'
export const ACTIVITY_STATUS_BILLED = 'billed'

export const TIMESHEETENTRY_STATUS_CREATED = 'created'
export const TIMESHEETENTRY_STATUS_CONFIRMED = 'confirmed'
export const TIMESHEETENTRY_STATUS_BILLED = 'billed'

export const BILLABLE_SLUG = 'billable'
export const NON_BILLABLE_SLUG = 'nonBillable'

export const CUSTOM_FIELD_TYPE_SLUG_TEXT = 'text'
export const CUSTOM_FIELD_TYPE_SLUG_TEXT_AREA = 'textarea'
export const CUSTOM_FIELD_TYPE_SLUG_DATETIME = 'datetime'
export const CUSTOM_FIELD_TYPE_SLUG_DATE = 'date'
export const CUSTOM_FIELD_TYPE_SLUG_TIME = 'time'
export const CUSTOM_FIELD_TYPE_SLUG_NUMBER = 'number'
export const CUSTOM_FIELD_TYPE_SLUG_BOOLEAN = 'boolean'
export const CUSTOM_FIELD_TYPE_SLUG_SELECT = 'select'

export const CALENDAR_SLOT_DURATION_OPTION_5MIN = 5 * 60 * 1000
export const CALENDAR_SLOT_DURATION_OPTION_10MIN = 10 * 60 * 1000
export const CALENDAR_SLOT_DURATION_OPTION_15MIN = 15 * 60 * 1000
export const CALENDAR_SLOT_DURATION_OPTION_30MIN = 30 * 60 * 1000

export const STRIPE_STATUS_SLUG_PAID = 'paid'

export const PRODUCT_SLUG_FREE = 'free'
export const PRODUCT_SLUG_PLUGIN = 'plugin'

export const PRODUCT_STATUS_SLUG_DRAFT = 'draft'
export const PRODUCT_STATUS_SLUG_ACTIVE = 'active'
export const PRODUCT_STATUS_SLUG_ARCHIVED = 'archived'

export const NOTIFICATION_TYPE_PERSISTENT = 'persistent'

export const TERMS_AND_CONDITIONS_URL = 'https://lexor.be/disclaimer'
export const PRIVACY_POLICY_URL = 'https://lexor.be/privacy-policy'

export const PERMISSION_ROLE_SLUG_ADMIN = 'admin'
export const PERMISSION_ROLE_SLUG_DEFAULT = 'default'

export const SYNC_SLUG_DEACTIVATED = 'deactivated'
export const SYNC_SLUG_PERMISSIONS_FAILED = 'permissionsfailed'
export const SYNC_SLUG_PERMISSIONS_SUCCES = 'permissionssuccess'
export const SYNC_SLUG_PERMISSIONS_UNKNOWN = 'permissionsunknown'

export const MAIN_PROJECT_SLUG = 'mainproject'
export const SUB_PROJECT_SLUG = 'subproject'

export const O365_TOKEN_EXPIRED_SLUG = 'o365delegatetokenexpirationexpired'
export const O365_TOKEN_ALERT_SLUG = 'o365delegatetokenexpirationalert'

export const MIN_DATETIME = '0001-01-01T00:00:00Z'

// ERROR_CODES
export const AUTHORIZATION_PASSWORD_FORGOTTEN_CODE = 'AADB2C90118'
export const AUTHORIZATION_PASSWORD_FORGOTTEN_CANCELED_CODE = 'AADB2C90091'

export const TIMESHEET_PREDICTION_GOOD = 'GOOD'
export const TIMESHEET_PREDICTION_WARNING = 'WARNING'
export const TIMESHEET_PREDICTION_CRITICAL = 'CRITICAL'
export const TIMESHEET_PREDICTION_UNKNOWN = 'UNKNOWN'
